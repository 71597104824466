/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable quotes */
export default [
  {
    header: 'Lists',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Forms Elements',
        icon: 'CopyIcon',
      },
      {
        title: 'Listings',
        route: 'listings',
        icon: 'ListIcon',
      },
      {
        title: 'Saved Properties',
        route: 'saved-properties',
        icon: 'SaveIcon',
      },
    ],
  },
  {
    title: 'Listings',
    route: 'listings',
    icon: 'ListIcon',
  },
  {
    title: 'Saved Properties',
    route: 'saved-properties',
    icon: 'SaveIcon',
  },
]
